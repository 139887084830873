$baseurl: '/images';

@import 'libs/vars';
@import 'libs/vars-mine';
@import 'libs/functions';
@import 'libs/mixins';
@import 'font-awesome.min.css';
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,600italic,800,800italic');

/*
	Spectral by HTML5 UP
	html5up.net | @n33co
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/

	@import 'libs/skel';

	@include skel-breakpoints((
		xlarge: '(max-width: 1680px)',
		large: '(max-width: 1280px)',
		medium: '(max-width: 980px)',
		small: '(max-width: 736px)',
		xsmall: '(max-width: 480px)'
	));

	@include skel-layout((
		reset: 'full',
		boxModel: 'border',
		grid: ( gutters: 1.5em )
	));

/* Basic */

	@-ms-viewport {
		width: device-width;
	}

	body {
		background: _palette(bg);

		&.is-loading {
			*, *:before, *:after {
				@include vendor('animation', 'none !important');
				@include vendor('transition', 'none !important');
			}
		}
	}

	body, input, select, textarea {
		color: _palette(fg);
		font-family: _font(family);
		font-size: 15pt;
		font-weight: _font(weight);
		letter-spacing: _size(letter-spacing);
		line-height: 1.65em;

		@include breakpoint(xlarge) {
			font-size: 13pt;
		}

		@include breakpoint(large) {
			font-size: 12pt;
		}

		@include breakpoint(small) {
			font-size: 11pt;
			letter-spacing: _size(letter-spacing) * 0.5;
		}
	}

	a {
		@include vendor('transition', ('color #{_duration(transitions)} ease', 'border-bottom-color #{_duration(transitions)} ease'));
		border-bottom: dotted 1px;
		color: inherit;
		text-decoration: none;

		&:hover {
			border-bottom-color: transparent;
		}
	}

	strong, b {
		color: _palette(fg-bold);
		font-weight: _font(weight-bold);
	}

	em, i {
		font-style: italic;
	}

	p {
		margin: 0 0 _size(element-margin) 0;
	}

	h1, h2, h3, h4, h5, h6 {
		color: _palette(fg-bold);
		font-weight: _font(weight-extrabold);
		letter-spacing: _size(letter-spacing-alt);
		line-height: 1em;
		margin: 0 0 (_size(element-margin) * 0.5) 0;
		text-transform: uppercase;

		a {
			color: inherit;
			text-decoration: none;
		}
	}

	h2 {
		font-size: 1.35em;
		line-height: 1.75em;

		@include breakpoint(small) {
			font-size: 1.1em;
			line-height: 1.65em;
		}
	}

	h3 {
		font-size: 1.15em;
		line-height: 1.75em;

		@include breakpoint(small) {
			font-size: 1em;
			line-height: 1.65em;
		}
	}

	h4 {
		font-size: 1em;
		line-height: 1.5em;
	}

	h5 {
		font-size: 0.8em;
		line-height: 1.5em;
	}

	h6 {
		font-size: 0.7em;
		line-height: 1.5em;
	}

	sub {
		font-size: 0.8em;
		position: relative;
		top: 0.5em;
	}

	sup {
		font-size: 0.8em;
		position: relative;
		top: -0.5em;
	}

	hr {
		border: 0;
		border-bottom: solid 2px _palette(border);
		margin: (_size(element-margin) * 1.5) 0;

		&.major {
			margin: (_size(element-margin) * 2.25) 0;
		}
	}

	blockquote {
		border-left: solid 4px _palette(border);
		font-style: italic;
		margin: 0 0 _size(element-margin) 0;
		padding: 0.5em 0 0.5em 2em;
	}

	code {
		background: _palette(border-bg);
		border-radius: 3px;
		font-family: _font(family-fixed);
		font-size: 0.9em;
		letter-spacing: 0;
		margin: 0 0.25em;
		padding: 0.25em 0.65em;
	}

	pre {
		-webkit-overflow-scrolling: touch;
		font-family: _font(family-fixed);
		font-size: 0.9em;
		margin: 0 0 _size(element-margin) 0;

		code {
			display: block;
			line-height: 1.75em;
			padding: 1em 1.5em;
			overflow-x: auto;
		}
	}

	.align-left {
		text-align: left;
	}

	.align-center {
		text-align: center;
	}

	.align-right {
		text-align: right;
	}

/* Section/Article */

	section, article {
		&.special {
			text-align: center;
		}
	}

	header {
		p {
			color: _palette(fg-light);
			position: relative;
			top: -0.25em;
		}

		h2 + p {
		}

		h3 + p {
			font-size: 1.1em;
		}

		h4 + p,
		h5 + p,
		h6 + p {
			font-size: 0.9em;
		}

		&.major {
			margin: 0 0 (_size(element-margin) * 1.75) 0;

			h2, h3, h4, h5, h6 {
				border-bottom: solid 2px _palette(border);
				display: inline-block;
				padding-bottom: 1em;
				position: relative;

				&:after {
					content: '';
					display: block;
					height: 1px;
				}
			}

			p {
				color: _palette(fg);
				top: 0;
			}

			@include breakpoint(small) {
				margin: 0 0 _size(element-margin) 0;
			}
		}

		@include breakpoint(medium) {
			br {
				display: none;
			}
		}
	}

/* Form */

	form {
		margin: 0 0 _size(element-margin) 0;
	}

	label {
		color: _palette(fg-bold);
		display: block;
		font-size: 0.9em;
		font-weight: _font(weight-bold);
		margin: 0 0 (_size(element-margin) * 0.5) 0;
	}

	input[type="text"],
	input[type="password"],
	input[type="email"],
	select,
	textarea {
		@include vendor('appearance', 'none');
		background: _palette(border-bg);
		border-radius: 3px;
		border: none;
		color: inherit;
		display: block;
		outline: 0;
		padding: 0 1em;
		text-decoration: none;
		width: 100%;

		&:invalid {
			box-shadow: none;
		}

		&:focus {
			box-shadow: 0 0 0 2px _palette(accent1, bg);
		}
	}

	.select-wrapper {
		@include icon;
		display: block;
		position: relative;

		&:before {
			@include vendor('pointer-events', 'none');
			color: _palette(border);
			content: '\f078';
			display: block;
			height: _size(element-height);
			line-height: _size(element-height);
			position: absolute;
			right: 0;
			text-align: center;
			top: 0;
			width: _size(element-height);
		}

		select::-ms-expand {
			display: none;
		}
	}

	input[type="text"],
	input[type="password"],
	input[type="email"],
	select {
		height: _size(element-height);
	}

	textarea {
		padding: 0.75em 1em;
	}

	input[type="checkbox"],
	input[type="radio"], {
		@include vendor('appearance', 'none');
		display: block;
		float: left;
		margin-right: -2em;
		opacity: 0;
		width: 1em;
		z-index: -1;

		& + label {
			@include icon;
			color: _palette(fg);
			cursor: pointer;
			display: inline-block;
			font-size: 1em;
			font-weight: _font(weight);
			padding-left: (_size(element-height) * 0.6) + 0.75em;
			padding-right: 0.75em;
			position: relative;

			&:before {
				background: _palette(border-bg);
				border-radius: 3px;
				content: '';
				display: inline-block;
				height: (_size(element-height) * 0.6);
				left: 0;
				line-height: (_size(element-height) * 0.575);
				position: absolute;
				text-align: center;
				top: 0;
				width: (_size(element-height) * 0.6);
			}
		}

		&:checked + label {
			&:before {
				background: _palette(bg);
				color: _palette(fg-bold);
				content: '\f00c';
			}
		}

		&:focus + label {
			&:before {
				box-shadow: 0 0 0 2px _palette(accent1, bg);
			}
		}
	}

	input[type="checkbox"] {
		& + label {
			&:before {
				border-radius: 3px;
			}
		}
	}

	input[type="radio"] {
		& + label {
			&:before {
				border-radius: 100%;
			}
		}
	}

	::-webkit-input-placeholder {
		color: _palette(fg-light) !important;
		opacity: 1.0;
	}

	:-moz-placeholder {
		color: _palette(fg-light) !important;
		opacity: 1.0;
	}

	::-moz-placeholder {
		color: _palette(fg-light) !important;
		opacity: 1.0;
	}

	:-ms-input-placeholder {
		color: _palette(fg-light) !important;
		opacity: 1.0;
	}

	.formerize-placeholder {
		color: _palette(fg-light) !important;
		opacity: 1.0;
	}

/* Box */

	.box {
		border-radius: 3px;
		border: solid 2px _palette(border);
		margin-bottom: _size(element-margin);
		padding: 1.5em;

		> :last-child,
		> :last-child > :last-child,
		> :last-child > :last-child > :last-child {
			margin-bottom: 0;
		}

		&.alt {
			border: 0;
			border-radius: 0;
			padding: 0;
		}
	}

/* Icon */

	.icon {
		@include icon;
		border-bottom: none;
		position: relative;

		> .label {
			display: none;
		}

		&.major {
			@include vendor('transform', 'rotate(-45deg)');
			border-radius: 3px;
			border: solid 2px _palette(border);
			display: inline-block;
			font-size: 1.35em;
			height: calc(3em + 2px);
			line-height: 3em;
			text-align: center;
			width: calc(3em + 2px);

			&:before {
				@include vendor('transform', 'rotate(45deg)');
				display: inline-block;
				font-size: 1.5em;
			}

			@include breakpoint(small) {
				font-size: 1em;
			}
		}

		&.style1 {
			color: _palette(accent2, bg);
		}

		&.style2 {
			color: _palette(accent3, bg);
		}

		&.style3 {
			color: _palette(accent4, bg);
		}
	}

/* Image */

	.image {
		border-radius: 3px;
		border: 0;
		display: inline-block;
		position: relative;

		img {
			border-radius: 3px;
			display: block;
		}

		&.left {
			float: left;
			margin: 0 2em 2em 0;
			top: 0.25em;
		}

		&.right {
			float: right;
			margin: 0 0 2em 2em;
			top: 0.25em;
		}

		&.left,
		&.right {
			max-width: 40%;

			img {
				width: 100%;
			}
		}

		&.fit {
			display: block;
			margin: 0 0 _size(element-margin) 0;
			width: 100%;

			img {
				width: 100%;
			}
		}
	}

/* List */

	ol {
		list-style: decimal;
		margin: 0 0 _size(element-margin) 0;
		padding-left: 1.25em;

		li {
			padding-left: 0.25em;
		}
	}

	ul {
		list-style: disc;
		margin: 0 0 _size(element-margin) 0;
		padding-left: 1em;

		li {
			padding-left: 0.5em;
		}

		&.alt {
			list-style: none;
			padding-left: 0;

			li {
				border-top: solid 1px _palette(border);
				padding: 0.5em 0;

				&:first-child {
					border-top: 0;
					padding-top: 0;
				}
			}
		}

		&.icons {
			cursor: default;
			list-style: none;
			padding-left: 0;

			li {
				display: inline-block;
				padding: 0 1em 0 0;

				&:last-child {
					padding-right: 0 !important;
				}
			}

			&.major {
				padding: 1em 0;

				li {
					padding-right: 3.5em;

					@include breakpoint(small) {
						padding: 0 1em !important;
					}
				}
			}
		}

		&.actions {
			cursor: default;
			list-style: none;
			padding-left: 0;

			li {
				display: inline-block;
				padding: 0 (_size(element-margin) * 0.75) 0 0;
				vertical-align: middle;

				&:last-child {
					padding-right: 0;
				}
			}

			&.small {
				li {
					padding: 0 (_size(element-margin) * 0.375) 0 0;
				}
			}

			&.vertical {
				li {
					display: block;
					padding: (_size(element-margin) * 0.75) 0 0 0;

					&:first-child {
						padding-top: 0;
					}

					> * {
						margin-bottom: 0;
					}
				}

				&.small {
					li {
						padding: (_size(element-margin) * 0.375) 0 0 0;

						&:first-child {
							padding-top: 0;
						}
					}
				}
			}

			&.fit {
				display: table;
				margin-left: (_size(element-margin) * -0.75);
				padding: 0;
				table-layout: fixed;
				width: calc(100% + #{(_size(element-margin) * 0.75)});

				li {
					display: table-cell;
					padding: 0 0 0 (_size(element-margin) * 0.75);

					> * {
						margin-bottom: 0;
					}
				}

				&.small {
					margin-left: (_size(element-margin) * -0.375);
					width: calc(100% + #{(_size(element-margin) * 0.375)});

					li {
						padding: 0 0 0 (_size(element-margin) * 0.375);
					}
				}
			}

			@include breakpoint(small) {
				li {
					display: block;
					padding: (_size(element-margin) * 0.5) 0 0 0;
					text-align: center;
					width: 100%;

					&:first-child {
						padding-top: 0;
					}

					> * {
						margin: 0 auto !important;
						max-width: 30em;
						width: 100%;

						&.icon {
							&:before {
								margin-left: -1em;
							}
						}
					}
				}

				&.small {
					li {
						padding: (_size(element-margin) * 0.25) 0 0 0;

						&:first-child {
							padding-top: 0;
						}
					}
				}
			}
		}
	}

	dl {
		margin: 0 0 _size(element-margin) 0;
	}

/* Table */

	.table-wrapper {
		-webkit-overflow-scrolling: touch;
		overflow-x: auto;
	}

	table {
		margin: 0 0 _size(element-margin) 0;
		width: 100%;

		tbody {
			tr {
				border: solid 1px _palette(border);
				border-left: 0;
				border-right: 0;

				&:nth-child(2n + 1) {
					background-color: _palette(border-bg);
				}
			}
		}

		td {
			padding: 0.75em 0.75em;
		}

		th {
			color: _palette(fg-bold);
			font-size: 0.9em;
			font-weight: _font(weight-bold);
			padding: 0 0.75em 0.75em 0.75em;
			text-align: left;
		}

		thead {
			border-bottom: solid 2px _palette(border);
		}

		tfoot {
			border-top: solid 2px _palette(border);
		}

		&.alt {
			border-collapse: separate;

			tbody {
				tr {
					td {
						border: solid 1px _palette(border);
						border-left-width: 0;
						border-top-width: 0;

						&:first-child {
							border-left-width: 1px;
						}
					}

					&:first-child {
						td {
							border-top-width: 1px;
						}
					}
				}
			}

			thead {
				border-bottom: 0;
			}

			tfoot {
				border-top: 0;
			}
		}
	}

/* Button */
body{
	input[type="submit"],
	input[type="reset"],
	input[type="button"],
	button,
	.button {
		@include vendor('appearance', 'none');
		@include vendor('transition', ('background-color #{_duration(transitions)} ease-in-out', 'color #{_duration(transitions)} ease-in-out'));
		background-color: transparent;
		border-radius: 3px;
		border: 0;
		box-shadow: inset 0 0 0 2px _palette(border);
		color: _palette(fg-bold);
		cursor: pointer;
		display: inline-block;
		font-size: 0.8em;
		font-weight: _font(weight-bold);
		height: 3.125em;
		letter-spacing: _size(letter-spacing-alt);
		line-height: 3.125em;
		padding: 0 2.75em;
		text-align: center;
		text-decoration: none;
		text-transform: uppercase;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		&:hover {
			background-color: _palette(border-bg);
		}

		&:active {
			background-color: _palette(border2-bg);
		}

		&.icon {
			&:before {
				margin-right: 0.5em;
			}
		}

		&.fit {
			display: block;
			margin: 0 0 (_size(element-margin) * 0.5) 0;
			width: 100%;
		}

		&.small {
			font-size: 0.8em;
		}

		&.big {
			font-size: 1.35em;
		}

		&.special {
			background-color: _palette(accent01, bg);
			box-shadow: none !important;
			color: _palette(accent6, fg-bold) !important;

			&:hover {
				background-color: lighten(_palette(accent01, bg), 5) !important;
			}

			&:active {
				background-color: darken(_palette(accent6, bg), 5) !important;
			}
		}

		&.disabled,
		&:disabled {
			@include vendor('pointer-events', 'none');
			opacity: 0.25;
		}

		@include breakpoint(small) {
			height: 3.75em;
			line-height: 3.75em;
		}
	}

}


/* Features */

	.features {
		@include vendor('display', 'flex');
		@include vendor('flex-wrap', 'wrap');
		@include vendor('justify-content', 'center');
		list-style: none;
		padding: 0;
		width: 100%;

		li {
			@include padding(4em, 4em, (0,0,0,2em));
			display: block;
			position: relative;
			text-align: left;
			width: 33%;

			@for $i from 1 through _misc(max-features) {
				$j: 0.035 * $i;

				&:nth-child(#{$i}) {
					background-color: rgba(0,0,0, $j);
				}
			}

			&:before {
				display: block;
				color: _palette(accent2, bg);
				position: absolute;
				left: 1.75em;
				top: 2.75em;
				font-size: 1.5em;
			}

			&:nth-child(1) {
				border-top-left-radius: 3px;
			}

			&:nth-child(2) {
				border-top-right-radius: 3px;
			}

			&:nth-last-child(1) {
				border-bottom-right-radius: 3px;
			}

			&:nth-last-child(2) {
				border-bottom-left-radius: 3px;
			}

			@include breakpoint(medium) {
				@include padding(3em, 2em);
				text-align: center;

				&:before {
					left: 0;
					margin: 0 0 (_size(element-margin) * 0.5) 0;
					position: relative;
					top: 0;
				}
			}

			@include breakpoint(small) {
				@include padding(3em, 0);
				background-color: transparent !important;
				border-top: solid 2px _palette(border);
				width: 100%;

				&:first-child {
					border-top: 0;
				}
			}
		}
	}

/* Spotlight Mine */

			/*.spotlight.telematic {
				background-color: white !important;
			}*/

			/*.spotlight.silex {
				background-color: rgba(56,60,133,1) !important;
			}*/

	.spotlight {
		@include vendor('align-items', 'center');
		@include vendor('display', 'flex');

		.image {
			@include vendor('order', '1');
			border-radius: 0;
			width: 40%;
			right: 0;

			img {
				border-radius: 0;
				width: 100%;
				display: none;
			}
			.img{
				border-radius: 0;
				width: 100%;
				min-height: 50vh;
				background-repeat: no-repeat;
				background-size:cover;
				background-position: center center;
			}
			.img.img-1{
				background-image: url("../images/zoologie.jpg");

			}
			.img.img-2{
				background-image: url("../images/sylex.jpg");
			}
			.img.img-3{
				background-image: url("../images/THESAURUS.png");
				background-size:contain;
			}
			.img.img-4{
				background-image: url("../images/telematic.jpg");
			}
			.img.img-5{
				background-image: url("../images/scrib.jpg");
			}
			.img.img-6{
				background-image: url("../images/banner-older1.jpg");
			}
		}

		.content {
			@include padding(2em, 4em);
			@include vendor('order', '2');
			max-width: 48em;
			width: 60%;
		}
		&:nth-child(1),&:nth-child(1) h2,&:nth-child(1) .button.fit{
			color: rgba(9,11,26,1);
			border-color:rgba(9,11,26,1);

		}
		&:nth-child(1) .button.fit{
			box-shadow:inset 0 0 0 2px #000;
		}

		&:nth-child(2n) {
			@include vendor('flex-direction', 'row-reverse');
		}

		@for $i from 1 through _misc(max-spotlights) {
			$j: 1 * $i;

			&:nth-child(#{$i}) {
				background-color: rgba(255,255,255, $j);
			}
			&:nth-child(2){
				background-color: rgba(9,11,26, $j);
			}
		}

		@include breakpoint(large) {
			.image {
				width: 45%;
			}

			.content {
				width: 55%;
			}
		}

		@include breakpoint(medium) {
			display: block;

			br {
				display: none;
			}

				.image {
					width: 100%;

					.img {
						min-height: 20vh;
					}

					.img.img-1 {
						background-image: url("../images/zoologie-xd.jpg");

					}

					.img.img-2 {
						background-image: url("../images/sylex-xd.jpg");
					}

					.img.img-3 {
						background-image: url("../images/datu-xd.jpg");
					}

					.img.img-4 {
						background-image: url("../images/telematic.jpg");
					}
				}

			.content {
				@include padding(2em, 3em);
				max-width: none;
				text-align: center;
				width: 100%;
			}

		}

		@include breakpoint(small) {
			.image{
				.img{
					min-height: 20vh;
					background-size: 100%;
					background-position: top center;

				}
			}

			.content {
				@include padding(3em, 3em);
				p{
					display: block;
					display: -webkit-box;
					max-width: 100%;
					margin: 0 auto;
					line-height: 2;
					-webkit-line-clamp: 3;
					-webkit-box-orient: vertical;
					overflow: hidden;
					text-overflow: ellipsis;
				}
				.actions{
					@include padding(1em, 1em);

				}
			}
		}
	}

/* Wrapper */

	@mixin wrapper($p) {
		background-color: _palette($p, bg);
		color: _palette($p, fg);

		// Basic

			strong, b {
				color: _palette($p, fg-bold);
			}

			h2, h3, h4, h5, h6 {
				color: _palette($p, fg-bold);
			}

			hr {
				border-color: _palette($p, border);
			}

			blockquote {
				border-color: _palette($p, border);
			}

			code {
				background: _palette($p, border-bg);
			}

		// Section/Article

			header {
				p {
					color: _palette($p, fg-light);
				}

				&.major {
					h2, h3, h4, h5, h6 {
						border-color: _palette($p, border);
					}

					p {
						color: _palette($p, fg);
					}
				}
			}

		// Form

			label {
				color: _palette($p, fg-bold);
			}

			input[type="text"],
			input[type="password"],
			input[type="email"],
			select,
			textarea {
				background: _palette($p, border-bg);
			}

			.select-wrapper {
				&:before {
					color: _palette($p, border);
				}
			}

			input[type="checkbox"],
			input[type="radio"], {
				& + label {
					color: _palette($p, fg);

					&:before {
						background: _palette($p, border-bg);
					}
				}

				&:checked + label {
					&:before {
						background: _palette($p, fg-bold);
						color: _palette($p, bg);
					}
				}
			}

			::-webkit-input-placeholder {
				color: _palette($p, fg-light) !important;
			}

			:-moz-placeholder {
				color: _palette($p, fg-light) !important;
			}

			::-moz-placeholder {
				color: _palette($p, fg-light) !important;
			}

			:-ms-input-placeholder {
				color: _palette($p, fg-light) !important;
			}

			.formerize-placeholder {
				color: _palette($p, fg-light) !important;
			}

		// Icon

			.icon {
				&.major {
					border-color: _palette($p, border);
				}
			}

		// List

			ul {
				&.alt {
					li {
						border-color: _palette($p, border);
					}
				}
			}

		// Table

			table {
				tbody {
					tr {
						border-color: _palette($p, border);

						&:nth-child(2n + 1) {
							background-color: _palette($p, border-bg);
						}
					}
				}

				th {
					color: _palette($p, fg-bold);
				}

				thead {
					border-color: _palette($p, border);
				}

				tfoot {
					border-color: _palette($p, border);
				}

				&.alt {
					tbody {
						tr {
							td {
								border-color: _palette($p, border);
							}
						}
					}
				}
			}

		// Button

			input[type="submit"],
			input[type="reset"],
			input[type="button"],
			button,
			.button {
				box-shadow: inset 0 0 0 2px _palette($p, border);
				color: _palette($p, fg-bold);

				&:hover {
					background-color: _palette($p, border-bg);
				}

				&:active {
					background-color: _palette($p, border2-bg);
				}
			}

		// Features

			.features {
				li {
					@include breakpoint(small) {
						border-top-color: _palette($p, border);
					}
				}
			}

	}

	.wrapper {
		@include padding(4em, 0);

		> .inner {
			width: 60em;
			margin: 0 auto;

			@include breakpoint(large) {
				width: 90%;
			}

			@include breakpoint(medium) {
				width: 100%;
			}
		}

		&.alt {
			padding: 0;
		}

		&.style1 {
			@include wrapper(accent1);
		}

		&.style2 {
			background-color: _palette(bg);
		}

		&.style3 {
			@include wrapper(accent5);
		}

		&.style4 {
			background-color: transparent;
		}

		&.style5 {
			@include wrapper(accent7);
			padding: 1em 0 0 2em;
		}

		@include breakpoint(medium) {
			@include padding(4em, 3em);
		}

		@include breakpoint(small) {
			@include padding(3em, 2em);
		}
	}

/* Page Wrapper + Menu */

	#page-wrapper {
		@include vendor('transition', 'opacity #{_duration(menu)} ease');
		opacity: 1;
		padding-top: 3em;

		&:before {
			background: rgba(0,0,0,0);
			content: '';
			display: block;
			display: none;
			height: 100%;
			left: 0;
			position: fixed;
			top: 0;
			width: 100%;
			z-index: _misc(z-index-base) + 1;
		}
	}

	body{

		#menu {
			@include vendor('transform', 'translateX(-20em)');
			@include vendor('transition', 'transform #{_duration(menu)} ease');
			-webkit-overflow-scrolling: touch;
			background: _palette(accent00, bg);
			color: _palette(accent1, fg-bold);
			border-right: 3px solid;
			border-image-source: linear-gradient(10deg, rgb(210, 23, 114), rgb(15, 166, 223), rgb(210, 23, 114));
			border-image-slice: 1;
			height: 100%;
			max-width: 80%;
			overflow-y: auto;
			padding: 2em 2em;
			position: fixed;
			left: 0;
			top: 0;
			width: 20em;
			z-index: _misc(z-index-base) + 2;

			ul {
				list-style: none;
				padding: 0;

				> li {
					border-top: solid 1px _palette(accent1, border);
					margin: 0.5em 0 0 0;
					padding: 0.5em 0 0 0;

					&:first-child {
						border-top: 0 !important;
						margin-top: 0 !important;
						padding-top: 0 !important;
					}

					> a {
						border: 0;
						color: inherit;
						display: block;
						font-size: 0.8em;
						letter-spacing: _size(letter-spacing-alt);
						outline: 0;
						text-decoration: none;
						text-transform: uppercase;

						@include breakpoint(small) {
							line-height: 3em;
						}
					}
				}
				> li:nth-child(6){
					/*display: none;*/
				}
			}

			.close {
				background-image: url('images/close.svg');
				background-position: 4.85em 1em;
				background-repeat: no-repeat;
				border: 0;
				cursor: pointer;
				display: block;
				height: 3em;
				position: absolute;
				right: 0;
				top: 0;
				vertical-align: middle;
				width: 7em;
			}

			@include breakpoint(small) {
				padding: 3em 1.5em;
			}
		}

	}


	body.is-menu-visible {
		#page-wrapper {
			opacity: 0.35;

			&:before {
				display: block;
			}
		}

		#menu {
			@include vendor('transform', 'translateX(0)');
		}
	}

/* Header */

	#header {
		@include vendor('transition', 'background-color #{_duration(transitions)} ease');
		background: _palette(bg);
		height: 3em;
		left: 0;
		line-height: 3em;
		position: fixed;
		top: 0;
		width: 100%;
		z-index: _misc(z-index-base);

		h1 {
			@include vendor('transition', 'opacity #{_duration(transitions)} ease');
			height: inherit;
			left: 8.25em;
			line-height: inherit;
			position: absolute;
			top: 0;

			a {
				border: 0;
				display: block;
				height: inherit;
				line-height: inherit;
				position: relative;
				z-index: 999;

				@include breakpoint(small) {
					font-size: 0.8em;
				}
			}
		}

		nav {
			height: inherit;
			line-height: inherit;
			position: absolute;
			left: 0;
			top: 0;

			> ul {
				list-style: none;
				margin: 0;
				padding: 0;
				white-space: nowrap;
				background-color: transparent;



				> li {
					display: inline-block;
					padding: 0;

					> a {
						border: 0;
						color: _palette(fg-bold);
						display: block;
						font-size: 0.8em;
						letter-spacing: _size(letter-spacing-alt);
						padding: 0 1.5em;
						text-transform: uppercase;

						&.menuToggle {
							outline: 0;
							position: relative;

							&:after {
								background-image: url('images/bars.svg');
								background-position: right center;
								background-repeat: no-repeat;
								content: '';
								display: inline-block;
								height: 3.75em;
								vertical-align: top;
								width: 2em;
							}

							@include breakpoint(small) {
								padding: 0 1.5em;

								span {
									display: none;
								}
							}
						}

						@include breakpoint(small) {
							padding: 0 0 0 1.5em;
						}
					}

					&:first-child {
						margin-left: 0;
					}
				}
			}
		}

		&.alt {
			background: transparent;

			h1 {
				@include vendor('pointer-events', 'none');
				opacity: 0;
			}
		}
	}

/* Banner */

	#banner {
		@include vendor('display', 'flex');
		@include vendor('flex-direction', 'column');
		@include vendor('justify-content', 'center');
		cursor: default;
		height: 100vh;
		min-height: 35em;
		overflow: hidden;
		position: relative;
		text-align: center;

		h2 {
			@include vendor('transform', 'scale(1)');
			@include vendor('transition', ('transform 0.5s ease', 'opacity 0.5s ease'));
			display: inline-block;
			font-size: 1.75em;
			opacity: 1;
			padding: 0.35em 1em;
			position: relative;
			z-index: 1;

			&:before, &:after {
				@include vendor('transition', 'width 0.85s ease');
				@include vendor('transition-delay', '0.25s');
				background: _palette(fg-bold);
				content: '';
				display: block;
				height: 2px;
				position: absolute;
				width: 100%;
			}

			&:before {
				top: 0;
				left: 0;
			}

			&:after {
				bottom: 0;
				right: 0;
			}
		}

		p {
			letter-spacing: _size(letter-spacing-alt);
			text-transform: uppercase;

			a {
				color: inherit;
			}
		}

		.more {
			@include vendor('transition', ('transform 0.75s ease', 'opacity 0.75s ease'));
			@include vendor('transition-delay', '3.5s');
			@include vendor('transform', 'translateY(0)');
			border: none;
			bottom: 0;
			color: inherit;
			font-size: 0.8em;
			height: 8.5em;
			left: 50%;
			letter-spacing: _size(letter-spacing-alt);
			margin-left: -8.5em;
			opacity: 1;
			outline: 0;
			padding-left: _size(letter-spacing-alt);
			position: absolute;
			text-align: center;
			text-transform: uppercase;
			width: 16em;
			z-index: 1;

			&:after {
				background-image: url('images/arrow.svg');
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;
				bottom: 4em;
				content: '';
				display: block;
				height: 1.5em;
				left: 50%;
				margin: 0 0 0 -0.75em;
				position: absolute;
				width: 1.5em;
			}
		}

		&:after {
			@include vendor('pointer-events', 'none');
			@include vendor('transition', 'opacity #{_duration(fadein)} ease-in-out');
			@include vendor('transition-delay', '1.25s');
			content: '';
			background: _palette(bg);
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			opacity: 0;
		}

		@include breakpoint(small) {
			@include padding(7em, 3em);
			height: inherit;
			min-height: 0;

			h2 {
				font-size: 1.25em;
			}

			br {
				display: none;
			}

			.more {
				display: none;
			}
		}
	}



	body.is-loading {
		#banner {
			h2 {
				@include vendor('transform', 'scale(0.95)');
				opacity: 0;

				&:before, &:after {
					width: 0;
				}
			}

			.more {
				@include vendor('transform', 'translateY(8.5em)');
				opacity: 0;
			}

			&:after {
				opacity: 1;
			}
		}
	}

/* CTA */

	#cta {
		.inner {
			@include vendor('display', 'flex');
			max-width: 45em;

			header {
				@include vendor('order', '1');
				padding-right: 3em;
				width: 70%;

				p {
					color: inherit;
				}
			}

			.actions {
				@include vendor('order', '2');
				width: 30%;
			}

			@include breakpoint(medium) {
				display: block;
				text-align: center;

				header {
					padding-right: 0;
					width: 100%;
				}

				.actions {
					margin-left: auto;
					margin-right: auto;
					max-width: 20em;
					width: 100%;
				}
			}

			@include breakpoint(small) {
				.actions {
					max-width: none;
				}
			}
		}
	}

/* Main */

	#main {
		> header {
			@include padding(12em, 0);
			@include vendor('background-image', ('linear-gradient(top, rgba(0,0,0,0.5), rgba(0,0,0,0.5))', 'url("#{$baseurl}/banner.jpg")'));
			background-attachment: fixed;
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
			text-align: center;

			h2 {
				font-size: 1.75em;
				margin: 0 0 (_size(element-margin) * 0.25) 0;
			}

			p {
				color: inherit;
				letter-spacing: _size(letter-spacing-alt);
				text-transform: uppercase;
				top: 0;

				a {
					color: inherit;
				}
			}

			@include breakpoint(xlarge) {
				@include padding(2em, 0);
			}

			@include breakpoint(large) {
				@include padding(8em, 3em);
			}

			@include breakpoint(medium) {
				@include padding(10em, 3em);
			}

			@include breakpoint(small) {
				@include padding(5em, 3em);

				h2 {
					font-size: 1.25em;
					margin: 0 0 (_size(element-margin) * 0.5) 0;
				}
			}
		}
	}

	body.is-mobile {
		#main {
			> header {
				background-attachment: scroll;
			}
		}
	}

/* Footer */

	#footer {
		@include padding(6em, 0);
		background-color: darken(_palette(bg), 8);
		text-align: center;
		.icons {
			font-size: 1.25em;

			a {
				color: _palette(fg-light);

				&:hover {
					color: _palette(fg);
				}
			}
		}
		.copyright {
			color: _palette(fg-light);
			font-size: 0.8em;
			letter-spacing: _size(letter-spacing-alt);
			list-style: none;
			padding: 0;
			text-transform: uppercase;

			li {
				border-left: solid 1px _palette(fg-light);
				display: inline-block;
				line-height: 1em;
				margin-left: 1em;
				padding-left: 1em;

				&:first-child {
					border-left: 0;
					margin-left: 0;
					padding-left: 0;
				}

				a {
					color: inherit;

					&:hover {
						color: _palette(fg);
					}
				}

				@include breakpoint(xsmall) {
					border: 0;
					display: block;
					line-height: 1.65em;
					margin: 0;
					padding: 0.5em 0;
				}
			}
		}
		@include breakpoint(medium) {
			@include padding(4em, 3em);
		}
		@include breakpoint(small) {
			@include padding(3em, 2em);
		}
	}

/* Landing */

	body.landing {
		#page-wrapper {
			@include vendor('background-image', ('linear-gradient(top, rgba(0,0,0,0.5), rgba(0,0,0,0.5))', 'url("#{$baseurl}/banner.jpg")'));
			background-attachment: fixed;
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
			padding-top: 0;
		}

		#footer {
			background-color: darken(transparentize(_palette(bg), 0.1), 8);
		}
	}

	body.is-mobile {
		&.landing {
			#page-wrapper {
				background: none;
			}

			#banner,
			.wrapper.style4 {
				@include vendor('background-image', ('linear-gradient(top, rgba(0,0,0,0.5), rgba(0,0,0,0.5))', 'url("#{$baseurl}/banner.jpg")'));
				background-position: center center;
				background-repeat: no-repeat;
				background-size: cover;
			}

			#footer {
				background-color: darken(_palette(bg), 8);
			}
		}
	}
	/*part of section index*/
	body{
		div#menu{
			h2.logo-savoirs{
				background-image: url("../images/logo-savoirs.png");
				background-position: left center;
				background-repeat: no-repeat;
				background-size: contain;
				padding: 1em 3em;
			}

		}

		#header{
			background: transparent;
			/*background: #090b1a;*/
		}
		/*txt css*/
		.date{
			background-color: _palette(accent5, bg) !important;
			color: white;
			border-radius: 0;
			font-size: 1.2em;
			margin: 0 0 0.5em 0.5em;
			padding: 0.25em 0.65em;
			display: block;
			width: 6.8em;//min-content;
		}
		.date_unique{
			background-color: _palette(accent5, bg) !important;
			color: white;
			border-radius: 0;
			font-size: 1.2em;
			margin: 0 0 0.5em 0.5em;
			padding: 0.25em 0.65em;
			display: block;
			width: 3.8em;//min-content;
		}
		.pths{
			//background-color: rgba(199,84,231,0.1) !important;
			border-radius: 0;
			font-size: 1em !important;
		}
		.button.small.ariane{
			font-size: 0.5em;
			margin: 0 0.5em 0 0;

		}
		code {
			background: rgba(255, 255, 255, 0.5) !important;
			color: #2E3842;
		}
		/*end of txt css*/

		.mosaic {

			div.\34 u {
				border: 3px solid transparent;
				padding: 0 !important;
				width: 16.6666667%;
			}
		}

		.iScrollVerticalScrollbar.iScrollLoneScrollbar{
			/*width: 14px !important;
			border-radius: 60px !important;*/
		}
		#fp-nav{
			top: 23%;
			background-color: rgba(0,0,0,0.0);
			border-bottom-left-radius: 60px;
			border-top-left-radius: 60px;
			padding: 0.5em 0;

			ul {
				li {
					margin: 1em;
					a{
						span{
							border-radius: 0;
							height: 8px;
							width: 8px;
						}
						span:hover{
							border-radius: 0;
							height: 16px;
							width: 16px;

						}
						&.active
						span{
							border-radius: 0;
							width: 20px;
						}
						&.active:hover
						span{
							border-radius: 0;
							width: 20px;
						}

					}
				}
				div.fp-tooltip{
					color: #000000;
					background: #FFFFFF;
					text-shadow: 2px 2px 0 #bcbcbc, 2px 2px 0 #9c9c9c;
					padding: 0 0.5em;
				}
			}
		}

		.fp-controlArrow.fp-prev{
			left: 50px ;
		}
		.fp-controlArrow.fp-next{
			right: 50px;
		}
		#fp-nav.fp-right{
			right: 0;
		}


		.fp-slidesNav.fp-bottom,#fp-nav{
			ul{
				margin: 0 auto;
				width: auto;
				text-align: center;
				li{
					a{
						border: none;
						span{
							background-color: #ec2f6f;

						}
					}
				}
			}
		}
		.fp-slidesNav.fp-bottom{
			bottom: 0px;
		}

		#section0{
			#banner{
				.more::after{
					background-image: url("../images/finger-right.png");
					transform: rotate(90deg);
					bottom: 1em;
					display: block;
					height: 4.5em;
					width: 4.5em;
					left: 40%;
				}

				.txt-opening {
					font-size: 1.2rem;
				}

				.language {
					display: none;
					opacity: 1;
					visibility: visible;
					left: 50%;
					margin-left: -71px;
					top: 2em;
					position: absolute;
					z-index: 99;
					.language-current {
						color: #004f69;
						margin: 15px 0 6px 15px;
						cursor: pointer;
						.flag {
							width: 35px;
							height: 24px;
							display: inline-block;
							margin: 0 17px 0 0;
							vertical-align: middle;
							background-image: url(https://alvarotrigo.com/fullPage/imgs/flags.png);
						}
						.flag-english {
							background-position: -35px 0;
						}
					}
					.language-triangle {
						position: absolute;
						top: 22px;
						left: 3px;
						fill: #fff;
						height: 6px;
						width: 8px;
					}
					.submenu {
						background: #fff;
						border-radius: 15px;
					}
					.languages {
						padding: 46px 0 15px 0;
						position: relative;
						margin-top: -46px;
						z-index: -1;
					}
					.submenu {
						background: aqua;
						border-radius: 15px;
						ul {
							list-style: none;
							padding: 0;
							margin: 0;
						}
						a {
							padding: 6px 15px;
							display: block;
							color: #004f69;
							.flag {
								width: 35px;
								height: 24px;
								display: inline-block;
								margin: 0 17px 0 0;
								vertical-align: middle;
								background-image: url(https://alvarotrigo.com/fullPage/imgs/flags.png);
							}
							.flag-russian {
								background-position: -70px 0;
							}
							.flag-korean {
								background-position: -105px 0;
							}
							.flag-spanish {
								background-position: -140px 0;
							}
							.flag-chinese {
								background-position: 0 0;
							}

						}
					}

				}
				.language.active .language-triangle {
					fill: #004f69;
					.languages {
						display: block;
					}
				}
			}
			h2.logo-savoirs{
				&::before{
				background: transparent;
				}
				&::after{
					background: transparent;
				}

				background-image: url("../images/logo-savoirs.png");
				background-position: center center;
				background-repeat: no-repeat;
				padding: 3em 10em;
			}

			@include breakpoint(xlarge) {
				border: 0px solid blue;
			}
			@include breakpoint(large) {
				border: 0px solid yellow;
			}
			@include breakpoint(medium) {
				border: 0px solid green;
				.inner{
					h2.logo-savoirs{
						padding: 3em 6em;
						background-size: contain;
					}
					max-width: 60%;
				}
			}

			@include breakpoint(small) {
				border: 0px solid red;
			}
			@include breakpoint(xsmall) {
				border: 0px solid pink;
				#banner{
					padding: 0;
				}
			}

			.news-01{
				background-color: rgba(255,255,255,1);
				#news-1 *{
					color: black;
				}
				.inner {
					width: 75%;
					h1{
						width: 50%;
					}
				}

			}

		}

		#section0,#section4{
			.fp-controlArrow {
				background-repeat: no-repeat;
				background-size: contain;
				background-color: transparent;
				border-color:transparent;
				padding: 2em;

				@include breakpoint(xlarge) {
					border: 0px solid blue;
				}
				@include breakpoint(large) {
					border: 0px solid yellow;
				}
				@include breakpoint(medium) {
					border: 0px solid green;
					padding: 1.5em;
				}

				@include breakpoint(small) {
					border: 0px solid red;
				}
				@include breakpoint(xsmall) {
					border: 0px solid pink;
				}
			}
			.fp-prev{
				background-image: url("../images/finger-left.png");
				@include breakpoint(medium) {
					margin-left: -1em;
				}
				@include breakpoint(small) {
					margin-left: -1.5em;
				}
			}
			.fp-next{
				background-image: url("../images/finger-right.png");
				@include breakpoint(medium) {
					margin-right:  -1em;
				}
				@include breakpoint(small) {
					margin-right:  -1.5em;
				}
			}
			.fp-nav{

			}


		}

		#section1{
			.telematic,.zoologie{
				min-height: 50vh;
			}
			.telematic{
				text-align: right;
			}
			@include breakpoint(xlarge) {
				border: 0px solid blue;
			}
			@include breakpoint(large) {
				border: 0px solid yellow;
			}
			@include breakpoint(medium) {
				border: 0px solid green;
				padding: 3em 0 0 0 !important;
			}

			@include breakpoint(small) {
				border: 0px solid red;
				.telematic{
					.content{
						padding:1em 1em  10em 1em;
					}
				}
				.zoologie{
					min-height: 40vh;
					.content{
						padding:0em 2em 1em 2em;
					}
				}
			}
			@include breakpoint(xsmall) {
				border: 0px solid pink;
				.zoologie{
					min-height: 40vh;
					.content{
						padding:0em 2em 1em 2em;
					}
				}
			}
		}
		#section2{

			.silex,.datu{
				min-height: 50vh;
			}
			.datu{
				text-align: right;
			}
			@include breakpoint(xlarge) {
				border: 0px solid blue;
			}
			@include breakpoint(large) {
				border: 0px solid yellow;
			}
			@include breakpoint(medium) {
				border: 0px solid green;
				padding: 3em 0 0 0 !important;

			}

			@include breakpoint(small) {
				border: 0px solid red;
				.silex{
					.content{
						padding:0em 2em 1em 2em;
					}
				}
				.datu{
					.content{
						padding:1em 1em 10em 1em;
					}
				}
			}
			@include breakpoint(xsmall) {
				border: 0 solid pink;
				.silex{
					.content{
						padding:0 2em 1em 2em;
					}
				}
				.datu{
					.content{
						padding-bottom: 10em;
					}
				}
			}
		}
		#section3{
			background-color: rgba(9, 11, 26, 0.8) !important;
			.wrapper {
				padding: 3em 0 0 0 ;
				background-image: url("../images/bg-t.gif");
				background-repeat: no-repeat;
				background-position: top 2em left 0;

				.inner {
					width: 90%;

					.img-thesaurus {
						background-image: url("../images/THESAURUS.png");
						background-repeat: no-repeat;
						background-size: contain;
						background-position: center center;
						width: 100%;
						height: 95vh;
						h2{
							padding-top: 1em;
						}
					}

				}
			}
			@include breakpoint(xlarge) {
				border: 0px solid blue;
			}
			@include breakpoint(large) {
				border: 0px solid yellow;
			}
			@include breakpoint(medium) {
				border: 0px solid green;
				.inner{
				}
			}
			@include breakpoint(small) {
				border: 0px solid red;
				.wrapper {
					padding:2em 0 0 0;
					.inner {
						.img-thesaurus {
							background-image: url("../images/THESAURUS_h.png");
							background-repeat: no-repeat;
							background-size: contain;
							width: 100%;
							height: 94vh;
							background-position: center center;
						}


						.\31 2 u {
						transform: rotate(90deg);
						background-image: url("../images/THESAURUS_h.png");
						background-repeat: no-repeat;
						background-size: contain;

						span {
							visibility: hidden;
						}
					}
					}
				}
			}
			@include breakpoint(xsmall) {
				border: 0px solid pink;
				.inner{


				}
			}

		}
		#section4{

			h2{
				padding-bottom: 0;
				span{
					display: none;
				}
			}

			code{
				font-size: 0.75em;
			}
			.fp-controlArrow.fp-prev {
				margin-left: -2.5em !important;
			}
			.fp-controlArrow.fp-next {
				margin-right: -4.5em !important;
			}

			#comite-00{
				height: 100vh;
				background-image:/*url("../images/Com-SA.png"),*/ linear-gradient(_palette(accent2, bg), _palette(accent22, bg));
				header,section {
					h2,p,li b,li{
						color: _palette(accent5, bg);
					}
				}

				.icons{
					.scientifique{background-image: url("../images/scientifique.png");}
					.pilotage{background-image: url("../images/pilotage.png");}
					.lecture{background-image: url("../images/lecture.png");}
					.realisation{background-image: url("../images/realisation.png");}
					.partenaires{background-image: url("../images/partenaires.png");}
					a{
						border: none;
						span.icon{
							@include vendor('transition', ('color #{_duration(transitions)} ease', 'transform #{_duration(transitions)} ease'));
							cursor: pointer;
							background-position: center center;
							background-size: 200px;
							background-repeat: no-repeat;
							height: calc(10em + 2px);
							width: calc(10em + 2px);
							transform: scale(0.7) rotate(45deg);
							-ms-transform:scale(0.7) rotate(45deg);
							-webkit-transform:scale(0.7) rotate(45deg);
						}
						span.icon:hover{
							transform: scale(1) rotate(0deg);
							-ms-transform:scale(1) rotate(0deg);
							-webkit-transform:scale(1) rotate(0deg);
						}
					}

				}

			}
			div[id^="comite-"] {

				header,section {
					h2,p,li b,li{
						color: _palette(accent5, bg);
					}
				}
				.inner {
					top: 5%;
					width: 75%;
					text-align: left;
					h2{
						.icon {
							background-position: center center;
							background-size: contain;
							background-repeat: no-repeat;
							height: calc(1em + 2px);
							width: calc(1em + 2px);
							margin-bottom: 0;
						}
					}

					.collapsible {
						width: 100%;
						box-shadow:none;
						cursor: pointer;
						text-align: left;
						outline: none;
						font-size: 15px;
						margin: 0;
					}

					.active, .collapsible:hover {
						color: #1d242a;
					}

					.collapsible:after {
						content: '\002B';
						color: white;
						font-weight: bold;
						float: right;
						margin-left: 5px;
					}

					.active:after {
						content: "\2212";
					}

					.content {
						padding: 0 18px;
						max-height: 0;
						overflow: visible;
						transition: max-height 0.2s ease-out;
						text-align: left;
					}
				}
			}
			#comite-11{
				.pilotage{
					background-image: url("../images/pilotage.png");
				}
			}
			#comite-22{
				.partenaires{
					background-image: url("../images/realisation.png");
				}
			}
			#comite-33{
				.realisation{
					background-image: url("../images/lecture.png");
				}
			}
			#comite-44{
				.scientifique{
					background-image: url("../images/scientifique.png");
				}
			}
			#comite-55{
				.lecture{
					background-image: url("../images/lecture.png");
				}
			}

			section{
				height: 100vh;
				background: linear-gradient(_palette(accent2, bg), _palette(accent22, bg));
			}
			section#comite-0{
				background:transparent;
			}
			@include breakpoint(xlarge) {
				border: 0px solid blue;
			}
			@include breakpoint(large) {
				border: 0px solid yellow;
			}
			@include breakpoint(medium) {
				border: 0px solid green;
			}

			@include breakpoint(small) {
				border: 0px solid red;
				h3.collapsible{
					font-size: 12px;
				}

			}
			@include breakpoint(xsmall) {
				border: 0px solid pink;
				div[id^="comite-"]{
					.inner{
						margin: 0;
						width: 100%;
						h3.collapsible{
							font-size: 12px;
						}
						h3{
							font-size: 12px;
						}
						h5{
							color: #505393;
						}
						li{
							b{
								font-size: 12px;
							}
							code{
								display: none;
							}
						}
					}
				}

			}



		}
		#section5{
			#partenaire{
				background-color:_palette(fg);
				min-height: 65vh;
				.inner{
					max-width: inherit;
					header{
						h2,p{
							color:_palette(bg);
						}
					}
				}
				.cp-col-container {
					display: flex;
					margin: 0 auto;
					width: 960px;
					text-align: center;
					flex-direction: row;
					.cp-col-17 {
						flex: 1 1 auto;
						width: calc(100% / 5);
						display: block;
						float: left;
						margin: 0;
						padding: 0;
						img {
							display: block;
							width: 80%;
							margin-top: 15px;
							max-width: 321px;
							opacity: 0.9;
						}
					}
					.cp-col-17:last-of-type { margin-right: 0; padding-bottom: 15px; }
					.cp-customerlogo { flex-direction: initial; }
				}
				@include breakpoint(medium) {

					header {

					}

					.cp-customerlogo{
						width:auto;
						.cp-col-17{
							img{
								width: 50% !important;
							}
						}
					}
				}

				@include breakpoint(small) {
					.cp-customerlogo{
						width:auto;
						.cp-col-17{
							img{
								width: 100% !important;
							}
						}
					}
				}
			}
			#footer{
				min-height: 25vh;
				padding: 2em 0 2em 0;
				@include breakpoint(medium) {

				}
				@include breakpoint(small) {
					border: 2px solid red;
					padding:1em ;
					font-size: 13px;
					li{
						line-height: 2em;
					}
				}
				@include breakpoint(xsmall) {
					border: 2px solid blue;
					padding:1em ;
					font-size: 12px;
					.icons{
						margin: 0 0 1em 0;
					}

				}

			}
		}
		#section6{
			.spotlight.idee{
				min-height: 100vh;
				height: 100vh;
				background-color: transparent;
				.image{
					height: 100vh;
					overflow: hidden;
					.diaporama {
						position: relative;
						display: block;
						top: 0;
						left: 0;
						margin: 0;
						padding: 0;
						img {
							display: block;
							position: absolute;
							top: 0;
							left: 0;
							height: 100vh;
							vertical-align: baseline;
						}
					}



					/* définition de l'animation sur les images */
					.diaporama img {
						animation: fade-in-out 8s ease infinite 0s;
						opacity: 0;
					}
					/* définition délai des images */
					.diaporama img:nth-child(1) { animation-delay: 0s;}
					.diaporama img:nth-child(2) { animation-delay: 2s;}
					.diaporama img:nth-child(3) { animation-delay: 4s;}
					.diaporama img:nth-child(4) { animation-delay: 6s;}
					/* définition des étapes */
					@keyframes fade-in-out {
						0%, 100% {
							opacity: 0;
						}
						12.50% {
							opacity: 1;
						}
						25% {
							opacity: 1;
						}
						37.50% {
							opacity: 0;
						}
					}




				}
				.content{
					max-width: 55em;
					p{
						font-size: 0.8em;
						line-height: 1.7em;
					}
					p:nth-child(3){
						text-indent: 3em;
					}
					.quote{
						font-size: 0.8em;
						line-height: 1.7em;
					}
				}
				.content,h2{
					color: white;
				}
				ul.actions{
					display: none;
				}

			}
			@include breakpoint(xlarge) {
				border: 0px solid blue;
			}
			@include breakpoint(large) {
				border: 0px solid yellow;
			}
			@include breakpoint(medium) {
				border: 0px solid green;
				.spotlight.idee{
					.image {
						height: 50vh;
						.img{
							min-height: 50vh;
						}
					}
					.content{

						p{
							overflow: inherit;

						}
					}
				}
			}

			@include breakpoint(small) {
				border: 0px solid red;
				.spotlight.idee{
					.image {
						height: 30vh;
						.img{
							min-height: 30vh;
						}
					}
					.content{
						p{
							text-align: left;
							display: -webkit-box;
							max-width: 100%;
							margin: 0 auto;
							line-height: 2;
							-webkit-line-clamp: 3;
							-webkit-box-orient: vertical;
							overflow: hidden;
							text-overflow: ellipsis;
						}
					}
					ul.actions{
						display: inherit;
						.button.fit{
							color: rgba(255,255,255,1);
							border-color:rgba(255,255,255,1);

						}
						 .button.fit{
							box-shadow:inset 0 0 0 2px #FFF;
						}
					}
				}

			}
			@include breakpoint(xsmall) {
				border: 0px solid pink;
				.spotlight.idee{
					.image {
						height: 30vh;
						.img{
							min-height: 30vh;
						}
					}
					.content{
						p{
							text-align: left;
							display: -webkit-box;
							max-width: 100%;
							margin: 0 auto;
							line-height: 2;
							-webkit-line-clamp: 3;
							-webkit-box-orient: vertical;
							overflow: hidden;
							text-overflow: ellipsis;
						}
					}
				}
			}


		}
		#section7{
			background-color: rgba(9, 11, 26, 0.6) !important;
		}
		#section8{
			.tei-img,.tei-text{
				min-height: 50vh;
				background-color: #090b1a;
			}

			.tei-img{
				background-image: url("../images/scrib.jpg");
				background-position: top -11em center;
			}
			.actions{
				display: none;
			}



			@include breakpoint(xlarge) {
				border: 0px solid blue;

				.tei-img{
					background-position: top -10em center;
				}
			}
			@include breakpoint(large) {
				border: 0px solid yellow;
				.tei-img{
					background-position: top -10em center;
				}
			}
			@include breakpoint(medium) {
				border: 0px solid green;
				.inner{
				}
			}

			@include breakpoint(small) {


			}
			@include breakpoint(xsmall) {
				border: 0px solid pink;
				.tei-img{
					background-position: top 2em center;
					background-size: 140%;
					background-repeat: no-repeat;
				}

			}
		}
		/*_______________________________________________________Page_______________________________________________________ */



		.pge-thesaurus{
			.image.fit {
				img {
					width: 60%;
					margin: 0 auto;
				}
			}

		}
			/*_______________________________________________________End of Page_______________________________________________________ */
		> div:nth-child(3) > ul:nth-child(1) > li:nth-child(3){
			/*display: none;*/
		}
	}
